import React from 'react';
import Header from '../header/Header';
import './Hero.css';

import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import hearts from '../../assets/heart.png';
import calories from '../../assets/calories.png';
import NumberCounter from 'number-counter';


const Hero = () => {
  return (
    <div className='hero' id='Home'>
        <div className='left-h'>
            <Header />
{/* the best ad */}
            <div className='the-best-ad'>
                <div></div>
                <span>the best fitness club in the town.</span>
            </div>

{/* hero heading             */}
            <div className='hero-text'>
                <div>
                    <span className='stroke-text'>
                        Shape</span>
                    <span>&nbsp;your</span>
                </div>
                <div>
                    <span>Ideal</span>
                    <span className='stroke-text'>
                    &nbsp;body</span>
                </div>
                <div>
                    <span>
                        In here we will help you to shape and build your ideal body.
                        <br /> Live up your life to fullest.
                        
                        
                    </span>
                </div>
            </div>

{/* figures */}
            <div className='figures'>
                <div>
                    <span>
                        <NumberCounter 
                        end={140} 
                        start={100} 
                        delay='2' 
                        preFix='+' />
                    </span>
                    <span>expert coaches</span>
                </div>
                <div>
                    <span>
                        <NumberCounter 
                        end={978} 
                        start={900} 
                        delay='2' 
                        preFix='+' />
                    </span>
                    <span>members joined</span>
                </div>
                <div>
                    <span>
                        <NumberCounter 
                        end={50} 
                        start={30} 
                        delay='2' 
                        preFix='+' />
                    </span>
                    <span>fitness program</span>
                </div>
            </div>

{/* hero button             */}
            <div className='hero-button'>
                <div className='btn'>Get Started</div>
                <div className='btn'>Learn More</div>
            </div>

        </div>
        <div className='right-h'>
            <button className='btn'>
                JOIN NOW
            </button>

            <div className='heart-rate'>
                <img src={hearts} alt=""  />
                <span>Heart Rate</span>
                <span>116bpm</span>
            </div>

            {/* hero images */}
            <img src={hero_image} alt="Hero Image" className='hero-image'/>
            <img src={hero_image_back} alt="Hero Image back" className='hero-image-back'/>

            {/* calories */}
            <div className='calories'>
                <img src={calories} alt="calories" />
                <div>
                    <span>Calories Burned</span>
                    <span>220 kj</span>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Hero