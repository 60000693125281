import React from 'react';
import './Header.css';
import logo from '../../assets/logo.png';
import bars from '../../assets/bars.png';
import { useState } from 'react';
import { Link } from 'react-scroll';

const Header = () => {

  const mobile = window.innerWidth<=768?true:false;
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <div className='header'>
        <img src={logo} className='logo'/>
        {menuOpened === false && mobile === true ? 
        (
          <div style={{
            backgroundColor: 'var(--appColor)',
            padding: '.8rem .8rem .5rem .8rem',
            borderRadius: '5px'
          }} 
          onClick={()=>setMenuOpened(true)}
          >
            <img src={bars} alt="bars" 
            style={{
              width: '1.5rem', 
              height: '1.5rem'
            }} />
          </div>
        ) : (
          <ul className='header-menu'>
            <li>
              <Link 
                onClick={()=>setMenuOpened(false)}
                activeClass='active'  
                to='Home' 
                spy={true} 
                smooth={true}
              >Home</Link>
            </li>
            <li>
              <Link 
                onClick={()=>setMenuOpened(false)} 
                to='Programs' 
                spy={true} 
                smooth={true}
              >Programs</Link>
            </li>
            <li>
              <Link onClick={()=>setMenuOpened(false)} 
              to='Reasons' spy={true} smooth={true}>
              Why Us
              </Link>
              </li>
            <li>
              <Link onClick={()=>setMenuOpened(false)} 
              to='Plans' spy={true} smooth={true}>
              Plans
             </Link>
              </li>
            <li>
              <Link 
              onClick={()=>setMenuOpened(false)} 
              to='Testimonials' spy={true} smooth={true} 
              >Testimonials</Link>
              </li>
          </ul>
        )}
    </div>
  );
};

export default Header;